
































import { Component, Watch, Vue } from 'vue-property-decorator';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import { CHART_CLICK_EVENT, TASQ_OFF_TARGET_TYPE } from '@/lib/constants';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getConfigEnv } from '@/utils/helpers';

@Component({
  components: {
    LineChart: () => import('@/lib/charts/lineChart'),
  },
})
export default class TasqProductionDataChart extends Vue {
  highlightChart = false;

  get productionData() {
    return tasqProductionDataChartModule.productionData;
  }

  get defermentLabelingData() {
    return defermentLabelingModule.chartLabelingData;
  }

  get isChartLoaded() {
    return this.productionData?.loaded;
  }

  created() {
     if(getConfigEnv('OPERATOR_LOWERCASED') !== 'swn'){
        this.chartData.datasets.splice(6,1)
        this.chartData.datasets.splice(6,1)
        this.chartData.datasets.splice(6,1)
     }
    this.$eventBus.$on('HIGHLIGHT_CHART', (val) => {
      this.highlightChart = val;
    })
  }

  chartData: any = {
    datasets: [
      {
        label: 'Oil Rate',
        data: [],
        borderColor: '#2CE6C2',
		    backgroundColor: '#2CE6C2',
        // backgroundColor: 'rgba(44,230,194,0.0)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#666A96',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#666A96',
        yAxisID: 'y-axis-1',
        spanGaps: true,
      },
      {
        label: 'Water rate',
        data: [],
        borderColor: '#0076FF',
        backgroundColor: 'rgba(0, 118, 255, 0.095)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#0076FF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#0076FF',
        yAxisID: 'y-axis-1',
        spanGaps: true,
      },
      {
        label: 'Gas rate',
        data: [],
        borderColor: '#f55d8b',
        backgroundColor: '#f55d8b',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#2CE6C2',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#2CE6C2',
        yAxisID: 'y-axis-0',
        spanGaps: true,
      },
      // {
      //   label: '',
      //   data: [],
      //   borderColor: '#6f00c1',
      //   backgroundColor: 'rgba(110,0,193,0.5)',
      //   tension: 0,
      //   borderWidth: 2,
      //   fill: false,
      //   pointRadius: 0,
      //   pointHitRadius: 5,
      //   borderDash: [10, 15],
      //   yAxisID: 'y-axis-0',
      //   spanGaps: true,
      // },
      {
        label: 'Target reference',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: true,
        borderDash: [9, 8],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target upper band',
        data: [],
        borderColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target lower band',
        data: [],
        borderColor: 'transparent',
        backgroundColor: 'rgba(44,230,194,0.25)',
        tension: 0,
        borderWidth: 2,
        fill: '-1',
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },

        {
        label: 'Target reference',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: true,
        borderDash: [9, 8],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target upper band',
        data: [],
        borderColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target lower band',
        data: [],
        borderColor: 'transparent',
        backgroundColor: 'rgba(44,230,194,0.25)',
        tension: 0,
        borderWidth: 2,
        fill: '-1',
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
    ],
    labels: [],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: "rgba(255,255,255,0.7)",
      }
    },
    animation: {
      duration: 500,
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#ffffff90',
          fontStyle: 'bold',
          maxTicksLimit: 8,
          minRotation: 50,
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [
        {
          position: 'left',
          type: 'linear',
          id: 'y-axis-0',
          gridLines: {
            color: 'rgba(255, 255, 255, 0.3)',
          },
          ticks: {
            fontColor: '#ffffff90',
            maxTicksLimit: 4,
            fontStyle: 'bold',
            min: 0,
          },
        },
        {
          position: 'right',
          type: 'linear',
          id: 'y-axis-1',
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#ffffff90',
            maxTicksLimit: 4,
            fontStyle: 'bold',
            min: 0,
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => tooltipItems[0].xLabel.slice(5).replace(/-/g, '/'),
        label: (tooltipItems) => `${tooltipItems.yLabel.toFixed()}
          ${this.chartData.datasets[tooltipItems.datasetIndex].unit || ''}`,
      },
    },
    onClick: (evt) => {
      const chart =  (this.$refs.chart as any).$data._chart;
      const item = chart.getElementAtEvent(evt);
      if (item[0]) {
        const date = this.chartData.labels[item[0]._index];
        this.$eventBus.$emit(CHART_CLICK_EVENT, date);
      }
    },
    annotation: {
      annotations: [
      ],
    },
  }

  @Watch('isChartLoaded', {
    immediate: true,
  })
  onChartLoadedChange() {
    if (!this.productionData.data?.length) return;

// Oil rate is 0
// Water rate is 1
// Gas rate is 2
	if (true) {
		this.chartData.datasets[6].yAxisID = 'y-axis-1';
		this.chartData.datasets[7].yAxisID = 'y-axis-1';
		this.chartData.datasets[8].yAxisID = 'y-axis-1';
		this.chartData.datasets[6].label = "Oil rate target"




		// this.chartData.datasets[1].borderColor = "rgba(44,230,194,0.1)" // Target upper band


		// this.chartData.datasets[2].borderColor = "transparent" // Target lower band
		// this.chartData.datasets[2].backgroundColor = "rgba(44,230,194,0.25)" // Target lower band

		// this.chartData.datasets[3].borderColor = "#2CE6C2" // Dashed target band
		// this.chartData.datasets[3].backgroundColor = "rgba(44,230,194,0.09)" // Dashed target band


		this.chartData.datasets[7].borderColor = "rgba(44,230,194,0.1)" // Target upper band


		this.chartData.datasets[8].borderColor = "transparent" // Target lower band
		this.chartData.datasets[8].backgroundColor = "rgba(44,230,194,0.25)" // Target lower band

		this.chartData.datasets[6].borderColor = "#2CE6C2" // Dashed target band
		// this.chartData.datasets[3].backgroundColor = "rgba(44,230,194,0.09)" // Dashed target band



	} 
  
  if(getConfigEnv('OPERATOR_LOWERCASED') === 'swn'){
		this.chartData.datasets[3].label = "Gas rate target"

   



		// this.chartData.datasets[0].borderColor = "#f55d8b"
		// this.chartData.datasets[0].backgroundColor = "#f55d8b"
    this.chartData.datasets[3].yAxisID = 'y-axis-0';
		this.chartData.datasets[4].yAxisID = 'y-axis-0';
		this.chartData.datasets[5].yAxisID = 'y-axis-0';


		this.chartData.datasets[4].borderColor = "rgba(245, 93, 139 ,0.1)" // Target upper band


		this.chartData.datasets[5].borderColor = "transparent" // Target lower band
		this.chartData.datasets[5].backgroundColor = "rgba(245, 93, 139,0.25)" // Target lower band

		this.chartData.datasets[3].borderColor = "#f55d8b" // Dashed target band
		// this.chartData.datasets[3].backgroundColor = "rgba(245, 93, 139,0.09)" // Dashed target band
	}else {


  // this.chartData.datasets.splice(7, 1);
  // this.chartData.datasets.splice(8, 1);
  //  this.chartData.datasets.splice(7, 1);

  }


// // Oil rate is 0
// // Water rate is 1
// // Gas rate is 2
// 


    [this.chartData.datasets[0].data] = this.productionData.data; // Oil rate 0
    [, this.chartData.datasets[1].data] = this.productionData.data; // Water rate 1
    [,, this.chartData.datasets[2].data] = this.productionData.data; // Gas rate 2
    [,,, this.chartData.datasets[4].data] = this.productionData.data; 
    [,,,, this.chartData.datasets[3].data] = this.productionData.data;
    [,,,,, this.chartData.datasets[5].data] = this.productionData.data;

    // if(getConfigEnv('OPERATOR_LOWERCASED') === 'swn'){

      [,,,,,, this.chartData.datasets[7].data] = this.productionData.data; 
    [,,,,,,, this.chartData.datasets[6].data] = this.productionData.data;
    [,,,,,,,, this.chartData.datasets[8].data] = this.productionData.data;
    // }


    
    

    this.chartData.labels = this.productionData.time;
    if (tasqsListModule.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE && this.defermentLabelingData) {
      const mappedDataset = this.defermentLabelingData.dataset.filter(i => i.Include).map(i => i.date);
	  var index = tasqProductionDataChartModule.defermentType == "oil_rate" ? 0 : 2
     this.chartData.datasets[index].pointBackgroundColor = this.chartData.labels.map(i => mappedDataset.includes(i) ? '#2CE6C2' : 'transparent');
      this.chartData.datasets[index].pointRadius = this.chartData.labels.map(i => mappedDataset.includes(i) ? 5 : 0);

    if(getConfigEnv('OPERATOR_LOWERCASED') === 'swn'){
      this.chartData.datasets[0].pointBackgroundColor = this.chartData.labels.map(i => mappedDataset.includes(i) ? '#2CE6C2' : 'transparent');
      this.chartData.datasets[0].pointRadius = this.chartData.labels.map(i => mappedDataset.includes(i) ? 5 : 0);
       this.chartData.datasets[2].pointBackgroundColor = this.chartData.labels.map(i => mappedDataset.includes(i) ? '#2CE6C2' : 'transparent');
      this.chartData.datasets[2].pointRadius = this.chartData.labels.map(i => mappedDataset.includes(i) ? 5 : 0);
    }
    }

    let p1 = this.chartData.datasets[3]
    let p2 = this.chartData.datasets[4]
    let p3 = this.chartData.datasets[5]
    let p4 = this.chartData.datasets[6]
    let p5 = this.chartData.datasets[7]
    let p6 = this.chartData.datasets[8]
    this.chartData.datasets[3] = p4
    this.chartData.datasets[4] = p5
    this.chartData.datasets[5] = p6
    this.chartData.datasets[6] = p1
    this.chartData.datasets[7] = p2
    this.chartData.datasets[8] = p3
    // this.chartData.datasets[3] = p4

    if(getConfigEnv('OPERATOR_LOWERCASED') !== 'swn'){
      this.chartData.datasets[0].yAxisID = 'y-axis-0';
            this.chartData.datasets[1].yAxisID = 'y-axis-0';
            this.chartData.datasets[2].yAxisID = 'y-axis-1';
	this.chartData.datasets[3].yAxisID = 'y-axis-0';
		this.chartData.datasets[4].yAxisID = 'y-axis-0';
		this.chartData.datasets[5].yAxisID = 'y-axis-0';
}else {
   

   this.chartData.datasets.splice(3,1)
    this.chartData.datasets.splice(3,1)
    this.chartData.datasets.splice(3,1)
}
    
  }
}
